import { FC } from 'react'

import { HomeIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Breadcrumbs } from '@mui/material'

import { navigate, routes } from '@redwoodjs/router'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import Button from '../Library/Button/Button'

interface OnboardingPageHeaderProps {
  currentPage?: {
    name: string
    url: string
  }
}

const OnboardingPageHeader: FC<OnboardingPageHeaderProps> = ({
  currentPage,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <div className="py-6 px-10 bg-white flex gap-4 items-center justify-between sticky top-0 z-10 border-b border-gray-300">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRightIcon className="w-4 h-4" />}
      >
        <Button
          variant="text"
          fullWidth={false}
          className="text-gray-600 hover:bg-gray-100 px-3"
          onClick={() => {
            navigate(routes.onboarding())
          }}
          startIcon={<HomeIcon className="w-5 h-5" />}
        >
          <span className="pt-0.5">Setup</span>
        </Button>
        {currentPage?.name && currentPage?.url && (
          <Button
            variant="text"
            fullWidth={false}
            className="text-gray-600 hover:bg-gray-100 px-4"
            onClick={() => {
              navigate(currentPage.url)
            }}
          >
            {currentPage.name}
          </Button>
        )}
      </Breadcrumbs>
      <Button
        endIcon={<ChevronRightIcon className="w-5 h-5" />}
        variant="text"
        fullWidth={false}
        className="hover:bg-indigo-100 text-indigo-600 px-4"
        onClick={() => {
          navigate(routes.home())
          trackEvent('Settings', 'onboarding - skip setup')
        }}
      >
        <span className="pt-0.5">Skip Setup</span>
      </Button>
    </div>
  )
}
export default OnboardingPageHeader
